export let rectification_questions = [];
rectification_questions[1] = "Ты младший ребенок в семье?"; // да / нет / я единственный ребенок
rectification_questions[2] = "Когда умерла бабушка по папе?"; // число
rectification_questions[3] = "Когда умерла бабушка по маме?"; // число
rectification_questions[4] = "Когда умер дедушка по папе?"; // число
rectification_questions[5] = "Когда умер дедушка по маме?"; // число
rectification_questions[6] = "Когда умерла мама?"; // число
rectification_questions[7] = "Когда умер папа?"; // число
rectification_questions[8] = "Кто умер от рака?"; // текст
rectification_questions[9] = "Кто умер внезапно?"; // текст
rectification_questions[10] = "Кто умер в чрезвычайных ситуациях?";  // текст
rectification_questions[11] = "Кто умер от инсульта?"; // текст
rectification_questions[12] = "В каком возрасте тонул/а?"; // число
rectification_questions[13] = "В каком возрасте были переломы?"; // число
rectification_questions[14] = "В каком возрасте были аварии?"; // число
rectification_questions[15] = "В каком возрасте были переезды?"; // число
rectification_questions[16] = "В каком возрасте были пожары?"; // число
rectification_questions[17] = "В каком возрасте были нападения?"; // число
rectification_questions[18] = "В каком возрасте были операции?"; // число
rectification_questions[19] = "В каком возрасте были разводы?"; // число
rectification_questions[20] = "В каком возрасте купили квартиру?"; // число
rectification_questions[21] = "В каком возрасте терял/а крупные суммы денег?"; // число

export let self_characteristic = [];
self_characteristic[1] = "Я хозяйственная. Мне нравится опекать, ощущать себя доброй и заботливой мамой. Причем, касается это даже людей старше меня. Чувствую себя в безопасности дома, обожаю гостей. Уверена в своих силах и стабильно, прочно стою на ногах.";
self_characteristic[2] = "Я скрытная настолько, что порой сама не могу понять ход своих мыслей. Внимание смущает, поэтому держусь подальше от общества. Но в уютной обстановке позволяю себе немного раскрыться. Обладаю железным терпением и усидчивостью, благодаря чему всегда довожу начатое до логичного финала.";
self_characteristic[3] = "Я упорная интеллектуалка, которая любит познавать новое. Единственная беда - мне тяжело объяснить, чего хочу. Возможно, потому что интересно сразу всё. Целью своей жизни вижу признание себя в качестве неукротимого профессионала.";
self_characteristic[4] = "Я любопытна и независима, сама выбираю всё и всех, кто меня окружает. Считаю себя интересной личностью, которой найдётся, что сказать в любой обстановке. Не боюсь осуждения и высказываюсь прямо.";
self_characteristic[5] = "Я добрая, нежная, тонкая натура. Эмоциональная, но неконфликтная. Мне проще промолчать, чем высказать недовольство. Я нуждаюсь в опоре, поэтому между карьерой и любовью точно выберу второе.";
self_characteristic[6] = "Я остро ощущаю чужие переживания и постоянно нуждаюсь в собственных свежих эмоциях. Пусть даже скандал - он способен вытащить меня из рутины и скуки. Всегда наношу макияж и олицетворяю женственность.";
self_characteristic[7] = "Я живая, энергичная, дисциплинированная. Всегда нахожусь в движении, люблю подчинять своей воле и контролировать окружающих. Мне важно быть правой, работать на руководящих должностях, вести активный образ жизни.";
self_characteristic[8] = "Я перфекционистка, которая не может жить без достижения целей. Ставлю их себе на день, неделю, месяц, годы. Люблю изменять локации, привычки, окружение. Нуждаюсь в ярких впечатлениях, без них чувствую себя опустошенной.";


export let characteristic_of_a_man = [];
characteristic_of_a_man[1] = "Моя опора во всём. Сильный, настойчивый, надёжный, стабильный и трудолюбивый. Он без проблем содержит семью, постоянно самосовершенствуется, радует меня подарками и вниманием.";
characteristic_of_a_man[2] = "Хозяйственный мужчина. Прекрасный папа и муж, который умеет опекать, дарить нежность, развиваться сам и развивать других. Считает своим долгом построить дом, обеспечить семью, дать родным всё необходимое. Но не отличается верностью.";
characteristic_of_a_man[3] = "Умный рыцарь. Справедливый, логичный, интеллектуально развитый, готовый во всём искать причинно-следственные связи. Ему порой не хватает эмоциональности, зато он точно знает, чего хочет.";
characteristic_of_a_man[4] = "Хитрец. Умный и прагматичный манипулятор, который рассчитывает наперед каждый шаг. Мне трудно на него положиться, ведь он постоянно меняют точку зрения и отличается нестабильностью. Иногда ведёт себя озлобленно и даже опасно.";
characteristic_of_a_man[5] = "Двуличный тип. Он - душа компании, имеет массу связей, считается великим человеком в своих кругах. Дома же снимает маску, становится неприятным, злым. Любит узнавать тайны, распускать сплетни, использовать информацию себе во благо. И всегда выходит сухим из воды.";
characteristic_of_a_man[6] = "Страстный эгоист. Говорит прямо, что думает, пишет стихи, красиво ухаживает, прекрасен в постели. В нём отлично всё, кроме того, что способен быстро перегореть в отношениях. А ещё переживает больше о своих чувствах, чем о моих.";
characteristic_of_a_man[7] = "Бесстрашный оптимист. Обладатель пылкого характера, который страдает из-за импульсивных решений. У него активная жизненная позиция, которая толкает на то, чтобы делать что-нибудь для общества и занимать лидирующие позиции. Из минусов: он не способен удержать то, что получает, иногда проявляет безудержную агрессию.";
characteristic_of_a_man[8] = "Герой, неуверенный в своих силах. Мужчина, действующий под импульсами, который подчиняет себе и часто становится главным в своих кругах. Не строит стратегий, живёт одним мгновением. Никогда не переживает про чужое мнение, берёт ответственность за свои поступки. Но если с первого раза идея не удалась, больше пробовать не станет.";


export let voice_instr = "В голосовом сообщении запишите мне ситуацию в которой Вы оказались. Расскажите все, что считаете поможет помочь сделать точный расклад. Опишите все нюансы от начала истории до настоящего времени. Длительно ауди не должна превышать 5 минут.";
